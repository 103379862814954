import { RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const NavItem = styled.div`
    ${RowFlex}
    cursor: pointer;
    gap: 1rem;
`
export const Title = styled.span`
    font-size: 1rem;
    color: ${({ active }) => (active ? '#FFFFFF' : '#888888')};
`

export const LoginWrapper = styled.div`
    display: flex;
    gap: 8px;
`
